import React, { lazy, Component, Suspense } from 'react';
import './App.css';
import Firebase, { FirebaseProvider, withFirebaseHOC } from './Firebase'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
const Login = lazy(() => import('./components/Login')) 
const Logout = lazy(() => import('./components/Logout')) 
const Signup = lazy(() => import('./components/Signup')) 
const Search = lazy(() => import('./components/Search')) 
const Profile = lazy(() => import('./components/Profile')) 
const User = lazy(() => import('./components/User')) 

/*
function User(props){
  return <h1>Hello {props.match.params.username}!</h1>;
}
*/

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      authUser: null,
      user: null,
      isLoginOpen : false,
      isJoinOpen : false,
      isLoading : true,
      toggleBurger: false

    };

  }

 

   async populateUser(authuser) {
    if(!authuser)
      this.setState({ authUser : false });

    

    const userRec = await Firebase.fetchUser(authuser.uid);
    if(userRec){
      this.setState({ authUser : authuser, user : userRec, isJoinOpen :false, isLoginOpen:false });
      console.log(authuser);
      var db = Firebase.db()
      console.log(authuser.uid)
      db.collection("followers").doc(authuser.uid).get().then(function(doc) {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
      }).catch(function(error) {
          console.log("Error getting documents: ", error);
      });;

    }else{
      //logged in but not user record

      const userData = {'username' : '', 'uid' : authuser.uid, full_name :  authuser.displayName, 'photo': authuser.photoURL}
      var newUser = await Firebase.createNewUser(userData);
       await this.populateUser(authuser);
    }
  }

  async componentDidMount() {
      try {
   // console.log(Firebase)
    this.listener =  Firebase.checkUserAuth(async (authUser) => {
      if (authUser) {
        this.populateUser(authUser);
        this.setState({isLoading: false})
      }
      else {
        this.setState({ authUser: false, user: false, isLoading: false });
            }
    })
      } catch (error) {
        console.error(error);
        this.setState({ authUser: null, user : null })
        
      } 
  }
 
  componentWillUnmount() {
    try{
      this.listener && this.listener();
    }
    catch(e){
      
    }
  }

  handleClose(){
    console.log('close');
  }

  toggleLoginModal = () =>{
      this.setState({
        isLoginOpen : !this.state.isLoginOpen
      })
  }

  toggleJoinModal = () =>{
    this.setState({
      isJoinOpen : !this.state.isJoinOpen
    })
  }

    toggleBurger = () =>{
      this.setState({
        toggleBurger : !this.state.toggleBurger
      })
    }



  render() {

    return (
    <FirebaseProvider value={Firebase}>
       <Router>
       <Suspense fallback={<div>Loading...</div>}>
       <nav className="navbar" role="navigation" aria-label="main navigation">
  <div className="navbar-brand">
    <a className="navbar-item" href="https://bulma.io">
      Logo
    </a>

    <a role="button" onClick={this.toggleBurger} className={'navbar-burger burger' + (this.state.toggleBurger ? ' is-active'  : '')} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample"  className={'navbar-menu' + (this.state.toggleBurger ? ' is-active'  : '')} >
    <div className="navbar-start">
        {this.state.user ? <><Link className="navbar-item" to={"/profile"}><figure className="image is-24x24"><img  className="is-rounded"  src={this.state.user.photo} /> </figure> {this.state.user.username || this.state.user.full_name}</Link> </>:  <Link className="navbar-item" to="/">Home</Link>}
        {this.state.user ?  <Link className="navbar-item is-primary" to="/explore">Explore</Link>  : ''}
    </div>

      <div className="navbar-end">
        <div className="navbar-item">
          <div className="buttons">
          {this.state.authUser ? 
          <Link to="/logout">Logout</Link>
          : <><a onClick={this.toggleJoinModal} className="button is-primary"><strong>Join</strong></a>
            <a onClick={this.toggleLoginModal} className="button is-light">Login</a></>
          }
          </div>
        </div>
      </div>
    </div>
  </nav>

  <section className="section">
          <div className="container">
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
        <Route path="/login">
            <Login authUser={this.state.authUser} user={this.state.user}/>
          </Route>
          <Route path="/join">
            <Signup authUser={this.state.authUser} user={this.state.user} />
          </Route>
          <Route path="/logout">
            <Logout authUser={this.state.authUser} user={this.state.user} />
          </Route>
          <Route exact path="/explore">
            <Search authUser={this.state.authUser} user={this.state.user}/>
          </Route>

          <Route exact path="/me">
            <Profile authUser={this.state.authUser} user={this.state.user}/>
          </Route>
          
          <Route path="/:username"  render={props => 
            (<User {...props} username={props.match.params.username} isAuthed={true} user={this.state.user} authUser={this.state.authUser} />)} />
              
          <Route exact path="/">
            <h1>Home</h1>
            <div className={'modal ' + (this.state.isLoginOpen ? ' is-active'  : '')}>
            <div className="modal-background" onClick={this.toggleLoginModal} ></div>
            <div className="modal-content">
                <div className="box"> 
                    <Login authUser={this.state.authUser} onLogin={this.toggleModal}/>
                </div>
              <button onClick={this.toggleLoginModal} className="modal-close is-large" aria-label="close"></button>
          </div>
          </div>



          <div className={'modal ' + (this.state.isJoinOpen ? ' is-active'  : '')}>
            <div className="modal-background" onClick={this.toggleJoinModal} ></div>
            <div className="modal-content">
                <div className="box"> 
                    <Signup authUser={this.state.authUser} onLogin={this.toggleJoinModal}/>
                </div>
              <button onClick={this.toggleJoinModal} className="modal-close is-large" aria-label="close"></button>
          </div>
          </div>
          </Route>
          
          
        </Switch>

      </div>


      

    </section>


    
        
      </Suspense>
    </Router>
    </FirebaseProvider>
  );
}
}

export default withFirebaseHOC(App)
