import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'

  
  const firebaseConfig = {
    apiKey: "AIzaSyAEfTtXxvFeMZCfr_Ma7mM81gC2qrS2zoE",
    authDomain: "workout-9ba22.firebaseapp.com",
    databaseURL: "https://workout-9ba22.firebaseio.com",
    projectId: "workout-9ba22",
    storageBucket: "workout-9ba22.appspot.com",
    messagingSenderId: "1052126047842",
    appId: "1:1052126047842:web:04b2c956164208368beb3b",
    measurementId: "G-TCHRMDHM53"
  };


  
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.firestore().enablePersistence();
  var g_provider = new firebase.auth.GoogleAuthProvider();
  g_provider.addScope('profile');
  g_provider.addScope('email');
  //g_provider.setCustomParameters({ prompt: 'select_account' });
  var fb_provider = new firebase.auth.FacebookAuthProvider();
  const defaultAnalytics = firebase.analytics();
  
  const Firebase = {
    // auth
    
    db : () =>{
      return firebase.firestore();

    },

    analytics : ()=>{
      return defaultAnalytics;
    },

    getRedirectResult : ()=>{
      firebase.auth().getRedirectResult().then(function(result) {
        if (result.credential) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken;
          // ...
        }
      }).catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        console.log(errorCode);
        if (errorCode === 'auth/account-exists-with-different-credential') {
          firebase.auth().fetchSignInMethodsForEmail(email).then(function(methods) {
            var provider = methods[0];
            console.log(provider);
            if(provider.indexOf('facebook') > -1 ){
              firebase.auth().signInWithPopup(fb_provider).then(function(result) {
                result.user.linkAndRetrieveDataWithCredential(credential).then(function(usercred) {
                    return usercred;
                });
              });
        
            }
          });



        }
        return null;
      });
    },

    loginWithFB : () => {
      
      firebase.auth().signInWithPopup(fb_provider).then(function(result) {
        // This gives you a Facebook Access Token.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
      }).catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;

        if (error.code === 'auth/account-exists-with-different-credential') {
          firebase.auth().fetchSignInMethodsForEmail(email).then(function(methods) {
            var provider = methods[0];
            if(provider.indexOf('google') > -1 ){
              firebase.auth().signInWithPopup(g_provider).then(function(result) {
                result.user.linkAndRetrieveDataWithCredential(credential).then(function(usercred) {
                    return usercred;
                });
              });
        
            }
          });



        }
        return null;
      });
    },

    loginWithGoogle : () =>{
       firebase.auth().signInWithRedirect(g_provider)      
    },

    loginWithEmail: (email, password) => {
      return firebase.auth().signInWithEmailAndPassword(email, password)
    },
    signupWithEmail: (email, password) => {
      return firebase.auth().createUserWithEmailAndPassword(email, password)
    },
    signOut: () => {
      console.log('Goodbye')
      return firebase.auth().signOut()
    },
    checkUserAuth: user => {
      return firebase.auth().onAuthStateChanged(user)
    },
  
    // firestore
    createNewUser: userData => {
      return firebase
        .firestore()
        .collection('users')
        .doc(`${userData.uid}`)
        .set(userData)
    },

    fetchPostsForUser: (uid, callback) =>{
      let posts = firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .collection('posts').orderBy("created", "desc")

     return  posts.onSnapshot(callback);
    },

    addPost: (post) => {
      let posts = firebase
      .firestore()
      .collection('users')
      .doc(post.user)
      .collection('posts')   

      return posts.add({
        text : post.html,
        created : firebase.firestore.Timestamp.now(),
        image: post.image

      });
    },

    fetchUsers: (callback) => {

      console.log('fetchUsers')

      let user = firebase
      .firestore()
      .collection('users')

     
      user.get().then(callback);

    },

    fetchUserByUsername : async(username) => {
      
      let user = firebase
      .firestore()
      .collection('users')
      .where("username", "==", username)


      return user.get();   
      
    },

    fetchUser: async(uid) => {
      let user = firebase
      .firestore()
      .collection('users')
      .doc(uid);

      let result = await user.get()
      if(result.exists)
        return result.data()
      
    },

    addPostImage : (image, file) => {

      var metadata = {
        contentType: image.type
      };

      console.log('addPostImage ');
      console.log(file[0])
      var user = firebase.auth().currentUser;
      var storageRef = firebase.storage().ref("user");
      var userRef = storageRef.child(user.uid);
      var uploadTask = userRef.child('images/'+image.name).put(image, metadata);;
      return uploadTask;
    },


    //Follower API

    getFollowers : (userId) => {
      // Used to build the follower count
      return  firebase
      .firestore()
      .collection('followers')
      .doc(userId)
    },

    getFollowing : (userId) => {
      // Used to get all following
      return  firebase
      .firestore()
      .collection('following')
      .doc(userId)
    },


    follow : (followerId , followedId) => {
      console.log('followedId = ' + followedId)
      firebase
      .firestore()
      .collection('followers').doc(`${followedId}`)
      .set({ [followerId]  : true } ,{ merge: true });
      
      return firebase
      .firestore()
      .collection('following').doc(`${followerId}`)
      .set({ [followedId]  : true } ,{ merge: true });

    },
    
    unfollow : (followerId , followedId) => {
      console.log('followedId = ' + followedId)
      firebase
      .firestore()
      .collection('followers').doc(`${followedId}`)
      .set({ [followerId]  : false } ,{ merge: true });
      
      return firebase
      .firestore()
      .collection('following').doc(`${followerId}`)
      .set({ [followedId]  : false } ,{ merge: true });

    }

  }
  
  export default Firebase